body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white; /* Default text color */


  background-image: linear-gradient(to top, #030213 0%, #260F4B 50%, #030213 100%);
  height: 100%; /* Ensures the gradient covers the full page height */
  width: 100%; /* Ensures the gradient covers the full page width */
  overflow-x: hidden; /* Prevents horizontal scrollbar if not needed */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
