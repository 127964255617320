.refundpolicy-section {
    margin-bottom: 5rem;
 
  }
  
  .refundpolicy-header {
    text-align: center;
    margin-top: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .refundpolicy-header {
      margin-top: 8rem;
    }
  }
  
  .refundpolicy-title {
    font-size: 2.25rem;
    color: white;
    font-weight: bold;
  }
  
  @media (min-width: 768px) {
    .refundpolicy-title {
      font-size: 3rem;
    }
  }
  
  .refundpolicy-content {
    display: flex;
    flex-direction: column;
    padding-right: 0.75rem;
    padding-left: 1.25rem;
    max-width: 100%;
    margin: 2.25rem auto 4rem;
  }
  
  @media (min-width: 768px) {
    .refundpolicy-content {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      max-width: 48rem;
    }
  }
  
  @media (min-width: 1024px) {
    .refundpolicy-content {
      max-width: 70rem;
    }
  }
  
  .refundpolicy-section-title {
    color: #6366f1;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
    padding-right: 20px;
  }
  
  @media (min-width: 768px) {
    .refundpolicy-section-title {
      font-size: 1.875rem;
      line-height: 2.25rem;
      margin-bottom: 0.75rem;
      margin-top: 2.25rem;
    }
  }
  
  .refundpolicy-section-text {
    color: #94a3b8;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 20px;
  }
  
  @media (min-width: 768px) {
    .refundpolicy-section-text {
      font-size: 1.125rem;
      line-height: 1.75rem;
      margin-bottom: 0.75rem;
    }
  }
  

  
  .refundpolicy-contact-link a {
    color: #8b5cf6;
    text-decoration: none;
  }
  
  .refundpolicy-contact-link a:hover {
    color: #f59e0b;
  }
  

  @media (max-width: 400px){
    .refundpolicy-title{
      font-size: 6vw;
    }

    .refundpolicy-section-title{
      font-size: 5vw;
      line-height: 1.2em;
      padding-right: 0px;

    }

    .refundpolicy-section-text{
      font-size: 3.5vw;
      padding-left: 0px;
      line-height: 1.2em;
      text-align: justify;
      
    }
  }