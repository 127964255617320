.custom-games {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .custom-games__btn-container {
    margin-top: 20px;
  }
  
  .custom-games__btn {
    background-color: transparent;
    padding: 10px 20px;
    border: 2px solid #261752;
    border-radius: 300px;
    text-transform: uppercase;
    cursor: pointer;
    transition: border-color 0.3s ease;
    margin-bottom: 6vh;
    margin-top: 6vh;
  }
  
  .custom-games__btn:hover {
    border-color: #ffffff;
  }
  
  .custom-games__btn-text {
    color: white;
    background-image: linear-gradient(90deg, #7F57F1, #7700fe, #F2B09F);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  font-size: 2vw;
  }
  
  .custom-games__title {
    color: #fff;
    font-size: 3vw;
    text-align: center;
    margin-top: 20px;
  }
  
  .custom-games__description-container {
    margin-top: 20px;
  }
  
  .custom-games__description {
    color: #d1d5db;
    text-align: center;
    padding: 0 10%;
    font-size: 1.5vw;
   
  }
  
  .custom-games__cards-container {
    display: flex;
   
    align-items: center;
    margin-top: 40px;
  }
  
  .custom-games__card {
    background-color: #1A202C;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 
 
  }


  .card-one, .card-two{
    margin: 3vw;
  }

  
  .custom-games__card-body {
    margin-top: 20px;
  }
  
  .custom-games__card-title {
    color: #E2E8F0;
    font-size: 2vw;
  }
  
  .custom-games__card-sessions {
    color: #CBD5E0;
    font-size: 1vw;
    margin-top: 5px;
  }
  
  .custom-games__card-text {
    color: #A0AEC0;
    margin-top: 15px;
    font-size: 1.5vw;
    line-height: 1.6;
  }
  
  .custom-games__card-actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .custom-games__card-link {
    color: #fff;
    background-color: #553C9A;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.5vw;
    display: inline-flex;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .custom-games__card-link:hover {
    background-color: #9F7AEA;
  }
  
  .custom-games__card-pricing {
    color: #fff;
    background-color: #2D3748;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 1.5vw;
  }
  
  .custom-games__image-container {
    width: 80%;  /* Ensures the container takes the full width of its parent */
    margin-top: 40px;
    display: flex;  /* Adding Flexbox */
    justify-content: center;  /* Centers the content horizontally */
 
}

.custom-games__image-container img {
    width: 100%;  /* Forces the image to expand and fill the container */
    border-radius: 8px;
  
   
}

  
  /* Responsive Styles */




  @media (max-width: 768px) {
   
    
    .custom-games__description {
      font-size: 1.25rem;
    }
  
    .custom-games__cards-container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .custom-games__card {
      margin: 20px;
    }
  
    .custom-games__card-sessions{
      font-size: 12px;
    }
    .custom-games__card-title {
      font-size: 2rem;
    }
  
    .custom-games__card-text {
      font-size: 1.125rem;
    }
  
    .custom-games__card-link, .custom-games__card-pricing {
      font-size: 1rem;
    }

    .custom-games__title, .custom-games__btn-text{
      font-size: 32px;
    }
  
   
  }


  @media (max-width: 400px){

    .custom-games__title,  .custom-games__btn-text, 
    .custom-games__card-title{
      font-size: 6vw;
    }

   

    .custom-games__description, .custom-games__card-sessions,
     .custom-games__card-text, .custom-games__card-link,
      .custom-games__card-pricing{
      font-size: calc(3px + 2vw);
    }
.custom-games__card-link, .custom-games__card-pricing{
  padding: 5px;
}
    
  }
  

  @media (max-width: 200px){
    .custom-games__btn{
      padding: 6px;
     padding-bottom: 12px;
    }

    .custom-games__card{
      margin-right: 5px;
      margin-left: 5px;
      padding-right: 5px;
      padding-left: 5px;
    }

    .custom-games__card-link, .custom-games__card-pricing{
      padding: 3px;
   
    }
  }

  @media (max-width: 100px){
    .custom-games__card-link, .custom-games__card-pricing{
      padding: 1.5px;
      font-size: 3.6px;
   
    }
  }
  
  @media (max-width: 70px){
  

    .custom-games__card{
      margin-right: 2px;
      margin-left: 2px;
      padding-right: 2px;
      padding-left: 2px;
    }
  }

  @media (max-width: 60px){
  

    .custom-games__card{
      margin-right: 1px;
      margin-left: 1px;
      padding-right: 1px;
      padding-left: 1px;
    }
  }