/* introModal.css */


.modalContainer {
  width: 66vw; /* 2/3 of the viewport width */
  max-width: 100vh;
  min-height: 50vh;
  height: 50vw; /* 2/3 of the viewport height */
  max-height: 80vh;
  padding: 16px; /* Adjust padding as needed */
  margin-top: 5rem; /* Adjust margin-top as needed for desktop */
  border-radius: 1rem; /* Rounded corners */
  background: linear-gradient(to top right, #20202b, #31015d, indigo); /* Gradient background */
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.videoContent {
  display: flex; /* Added to allow the iframe to flexibly fill the container */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  padding: 5%;
  height: 100%;
  width: 100%;
  background: black; /* Ensures the padding area has a black background */
  border-radius: 0.5rem; /* Optional: Keeps the rounded corners */
}

/* Ensure the iframe itself takes up the full width and height */
.videoIframe {
  width: 100%;
  height: 100%;
  border: none; /* Remove default iframe border */
  display: block; /* Ensures the iframe behaves like a block element */
}

/* Target the inner div of the video player */
.videoIframe,
.videoIframe * {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}



.modalButton {
  margin-top: 5%;
  margin-bottom: 1.5%;
  align-self: center;
}

.vp-video{
  min-height: 40vh;
}

@media (max-width: 400px){
  .modalButton{
    margin-top: 20px;
    font-size: 10px;
    padding: 5px;
  }
}

@media (max-width: 100px){
  .modalButton{
    font-size: 4px;
    padding: 2px;
  }
}