.custom-hr {
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
  background-image: linear-gradient(to right, #060720, #667eea, #060720);
  border: none;
}

.joinus-container {
  height: 80vh;
  min-height: 300px;
  background-image: linear-gradient(to top, #030213, #260F4B, #030213);
  position: relative;
  display: grid;
  grid-template-rows: 5% 90% 5%;
}

.center-content {
  display: grid;
  grid-row-start: 2;
  grid-row-end: 3;
}

.title-container {
  font-size: 4vw; 
  font-weight: bold; /* Semibold */
  width: 75%; /* w-9/12 for mobile */
  margin: auto;
  text-align: center;
}

.title-container .title-text {
  color: white;
}

.highlight-text {
  font-family: 'Rubik Doodle Shadow', system-ui;
  color: #A67CFF; /* Coding text color */
  letter-spacing: 0.1em;
  text-shadow: 7px 7px 10px rgba(0, 0, 0, 0.5);
  margin-left: 0.5rem;

}


.laptop-button-container {
  text-align: center;
  font-size: 16px; /* md: text-lg for mobile */
  color: #718096; /* slate-300 */
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: margin-top 0.3s; /* smooth transition for margin changes */
  margin-top: 160px;
}

.mobile-button-container{
  display: none;
}



.button-background,
.laptop-button-background {
  position: absolute;
  transition: all 1s;
  opacity: 0.7;
 
  border-radius: 12px; /* rounded-xl */
  filter: blur(20px);
}

.button-link,
.laptop-button-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px; /* py-3 px-6 for mobile */
  font-size: 2vw;
  font-weight: bold;
  color: white;
  background-image: linear-gradient(to right, #4f46e5, #9333ea);
  border: 1px solid #718096;
  border-radius: 300px;
  text-decoration: none;
}



@media (max-width: 767px) {
  .mobile-button-container {
    text-align: center;
    font-size: 16px; /* md: text-lg for mobile */
    color: #718096; /* slate-300 */
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: margin-top 0.3s; /* smooth transition for margin changes */
    margin-top: 100px;
  }
.laptop-button-container{
  display: none;
}
  
.title-text{
  font-size: 6vw;
}

.button-link{
  font-size: 16px;
}

}

@media (max-height: 600px){

  .mobile-button-container{

    margin-top: 50px;
  }
}


@media (max-width: 400px){
  .button-link{
    font-size: 4vw;
    padding: 6px;
  }

#text{
  margin-left: 2px;
}

.laptop-button-container{
  display: none;

}


}
