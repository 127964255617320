.faq-section {
    background-color: #04012c; /* Dark blue background */
    color: white;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .faq-header {
    text-align: center;
    margin-top: 20px;
  }
  
  .faq-button {
    background-color: transparent;
    border: 2px solid #261752;
    padding: 10px 24px;
    border-radius: 300px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .faq-title {
    background: linear-gradient(to right, #7F57F1, #7700fe, #F2B09F);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent; /* Fallback for non-WebKit browsers */
    font-size: 2vw;
  }
  
  
  .faq-subtitle {
    font-size: 3vw;
    text-align: center;
    margin-top: 4vh;
  }
  
  .faq-intro {
    font-size: 1.5vw;
    text-align: center;
    margin-top: 4vh;
    margin-bottom: 6vh;
  }
  
  .faq-content {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    margin-top: 20px;
  }
  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .faq-question-header {
    background: linear-gradient(to right, #04012c, #130250);
    padding: 10px;
    border-bottom: 1px solid gray;
  }
  
  .faq-question-button {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #8e73fa;
    font-size: 18px;
    padding: 10px;
  

   
  }
  
  .faq-icon {
    width: 20px; /* Fixed width */
    height: 20px; /* Fixed height */
    transform: rotate(-180deg);
    transition: transform 0.3s ease-in-out;
    margin-left: 20px;
  }
  
  .show .faq-icon {
    transform: rotate(0deg);
  }
  
  .faq-answer {
    display: none;
  }
  
  .show {
    display: block;
    background: linear-gradient(to right, #110428, #3e08bb);
    padding: 10px;
  }
  
  .more-faqs {
    text-align: center;
    margin-top: 20px;
  }
  
  .more-faqs-button {
    background: linear-gradient(to right, indigo, purple);
    padding: 10px 28px;
    font-size: 2vw;
    color: white;
    border-radius: 300px;
    border: 1px solid #777;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-top: 2vh;
    margin-bottom: 2vw;

  }
  
  .more-faqs-button:hover {
    background: linear-gradient(to right, #1a237e, #6a1b9a);
    transform: scale(1.1);
  }

  .question-text{
    width: 80%;
    font-size: 1.5vw;
  }

  .homepage-faq-answers{
    font-size: 1.5vw;
  }
  

  @media (max-width: 767px){

    .faq-subtitle, .faq-title{
      font-size: 32px;
    }

    .faq-intro{
      font-size: 18px;
    }

    .question-text, .homepage-faq-answers, .more-faqs-button{
      font-size: 16px;

    }
  }

 

  @media (max-width: 400px){
    .faq-question-button{
      font-size: calc(8px + 1vw);
     }

   .faq-icon {
    width: 10px;
    height: 10px; 
   }

   .faq-title{
    font-size: 6vw;
   }

   .faq-subtitle{
    font-size: 6vw;
   }
   .faq-intro{
    font-size: 4vw;
   }

   .more-faqs-button{
    font-size: 12px;
    padding: 10px;
   }

   .question-text, .homepage-faq-answers{
    font-size: calc(3px + 2vw);
   }


  
  }

  @media (max-width: 200px){

    .faq-question-button{
      flex-direction: column;
    }
    .question-text{
      width: 100%;
    }

    .faq-icon{
      margin-top: 20px;
      width: 5px;
      height: 5px;
      margin-left: 0px;

    }

    .more-faqs-button{
      font-size: 4vw;
      padding: 6px;
    }

    .faq-button{
      padding: 6px;
    }

    .faq-content{
      padding: 0;
    }

  }

  @media(max-width: 100px){

    .faq-question-button{
      padding: 0;
    }

    .faq-section {
      padding: 0px;
    }
  }