/* cardSlider.css*/


.card-slider {
   
    display: flex;
    flex-direction: row; /* Ensure the cards are laid out in a row */
    justify-content: center;
    align-items: center;
 
    scroll-snap-type: x mandatory; /* Snap behavior for smooth scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    margin-left: 2%;
    margin-right: 2%;
    
    gap: 10%;
}



.card-slider::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}



.course-arrow {
    position: absolute;
   
   
    background-color: indigo;
    color: white;
    border: none;
    padding: 10px;

    cursor: pointer;
    font-size: 2vw;
   

   
}

.course-arrow.left {
    left: 5%; 
    padding-right: 13px;
}

.course-arrow.right {
    right: 5%; 
    padding-left: 13px;
}

@media(max-width: 400px){

    .course-arrow.left{
        left: 2.5%;
    }

    .course-arrow.right {
        right: 2.55%; 
     
    }
}


@media(max-width: 200px){

    .course-arrow {
    
        padding: 4px;
    
        cursor: pointer;
        font-size: 10px;
          
    }

    .course-arrow.left{
       padding-right: 6px;
    }

    .course-arrow.right {
       padding-left: 6px;
     
    }
}

@media (max-width: 100px){
    .course-arrow{
        font-size: 4px;
        padding: 2px;
    }

    .course-arrow.left{
        padding-right: 3px;
     }
 
     .course-arrow.right {
        padding-left: 3px;
      
     }
}
