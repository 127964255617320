.faqpage-more-faqs {
    margin-top: -80px;
}

.faqpage-content-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
}

.faqpage-header {
    text-align: center;
    margin-top: 1rem;
    margin-top: 10rem; /* for medium screens and above */
}

.faqpage-header-title {
    font-size: 2.5rem; /* for medium screens */
    font-size: 3.125rem; /* for large screens */
    color: white;
    font-weight: bold;
}

.faqpage-faq-list {
    display: flex;
    flex-direction: column;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 100%; /* for small screens */
    max-width: 50rem; /* for medium screens */
    max-width: 87.5rem; /* for large screens */
    margin: 2.5rem auto;
}

.faqpage-accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-top: 1.25rem; /* for medium screens */
    padding-bottom: 1.25rem; /* for medium screens */
    text-align: left;
    color: #8e73fa;
    font-weight: bold;
    letter-spacing: wider;
    font-size: 0.875rem; /* for small screens */
    font-size: 1.25rem; /* for medium screens */
    border-bottom: 1px solid #666;
    background: transparent;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-left: 1.25rem; /* for medium screens */
    padding-right: 1.25rem; /* for medium screens */
    gap: 0.75rem;
}

.faqpage-collapsed {
    color: #7A58FF;
}

.faqpage-icon {
    width: 0.75rem;
    height: 0.75rem;
}

.faqpage-rotate {
    transform: rotate(180deg);
}

.faqpage-accordion-body {
    transition: all 0.3s;
}

.faqpage-show {
    display: flex;
}

.faqpage-hide {
    display: none;
}

.faqpage-accordion-content {
    padding-top: 0.5rem;
    padding-bottom: 1.25rem; /* for medium screens */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 1.25rem; /* for medium screens */
    padding-right: 1.25rem; /* for medium screens */
    font-size: 0.875rem; /* for small screens */
    font-size: 1.25rem; /* for medium screens */
    text-align: left;
    color: #ccc;
    background: linear-gradient(to right, #110428, #3e08bb);
    width: 100%;
}

.faqpage-answer {
    color: white;
}

.faqpage-talk-link-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 5rem;
}

.faqpage-talk-link {
    background: linear-gradient(to right, #221346, #462E88);
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 2.5rem;
    border: 1px solid #666;
    text-decoration: none;
}

.faqpage-talk-link:hover {
    background: transparent;
    background: linear-gradient(to left, #221346, #462E88);
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}


.faqpage-question-text{
font-size: 2vw;
    width: 80%;
}

.faqpage-answer, .faqpage-talk-link{
    font-size: 2vw;
}

.faqpage-header-title{
    font-size: 4.5vw;
    margin-bottom: 6vh;
}

.faqpage-talk-link{
    margin-top: 4vh;
    margin-bottom: 4vh;
}

@media(max-width: 1500px){

        
        .faqpage-answer, .faqpage-talk-link, .faqpage-question-text{
            font-size: 24px;
        }
        
        .faqpage-header-title{
            font-size: 48px;
        }
}

@media(max-width: 400px){

    .faqpage-header-title{
        font-size: 8vw;
    }

    .faqpage-question-text, .faqpage-answer, .faqpage-talk-link{
        font-size: 5vw;
    }

   
    .faqpage-talk-link-wrapper{
        margin-left: 15px;
        margin-right: 15px;
        text-align: center;
      
    }
}

@media (max-width: 150px){
.faqpage-faq-list, .faqpage-accordion-header, .faqpage-accordion-content{
    padding-right: 0;
    padding-left: 0;

}

.faqpage-accordion-header{
    flex-direction: column;
    padding-top: 6px;
    padding-bottom: 0;
   gap: 0;
}
.faqpage-icon{
    width: 3px;
    margin-bottom: 0;
}

.faqpage-talk-link{

    padding: 2px;
}
    
}