

/* card.css */


  /* Main card styles */
  .card {

   height: 100%;
   width: 20vw;
   

    padding-bottom: 1rem;
    border-radius: 0.75rem;
    border: 1px solid #311e69;
    background: linear-gradient(to right, #030213, #261752);
    transition: all 300ms ease-in-out;
  }
  
  .card:hover {
    background: linear-gradient(to right, #030213, #2a0f7b);
  }

  .card-container{
    max-width: 30%;

    
  
    
     
  }
  
  /* Article within the card */
  .card-article {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: default;
  }
  
  .card-image {
    width: 100%;
    padding-top: 75%;
    position: relative;
  }
  
  .card-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  
  .card-content {
    padding: 0.5rem 1rem;
  }
  
  .card-title h3 {
    font-weight: bold;
    color: white;
    font-size: clamp(1rem, 10vh, 2.5vh);
    text-align: center;
    margin-bottom: 2vh;
  }
  
  .card-description {
    color: slategray;
    overflow: hidden;
    text-align: justify;
    font-size: 1.5vh;
    height: 20vh;

  }
  
  /* Buttons */
  .card-buttons {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin-top: 4vh;
    margin-bottom: 4vh;
    
  }
  
  .btn-curriculum, .btn-book {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 3rem;
    font-size: 1.5vh;
    color: white;
    border-radius: 0.875rem;
    border: 1px solid slategray;
    transition: all 250ms ease-in-out;
  }
  
  .btn-curriculum {
    background: linear-gradient(to right, #3b007a, #6a0089, #900096);
  }
  
  .btn-book {
    background: linear-gradient(to right, #2400a3, #5400b4, #a3766a);
  }
  
  .btn-curriculum:hover, .btn-book:hover {
    transform: scale(1.1);
    background: linear-gradient(to right, #7F57F1, #7700fe, #F2B09F);
  }
  
  /* Responsive adjustments */


@media (min-width: 2100px){

  .card-description, .btn-curriculum, .btn-book, .card-title h3{
    font-size: 2vh
  }
}


  @media (max-width: 1500px) {

    .card{
    width: 30vw;
    }

    .card-container{
      max-width: 80%;
    }
   
  }

  @media (max-width: 1000px){
    .card{
      width: 50vw;
      }
  
      .card-container{
        max-width: 100%;
      }
     
  }
  
  @media (max-width: 500px) {
    .card {
      max-height: 800px;
    }

    .card-description{
      font-size: 14px;
      height: 120px;
    }

    .btn-curriculum, .btn-book{
      font-size: 16px;
    }

    .card-content h3{

      font-size: 6vw;
    }

  }

 @media (max-width: 400px){

  .card-description{
    display: none;
  }

  .card{
    height: 450px;
  }

  .card-container{
    max-height: 450px;
  }

  .btn-curriculum, .btn-book{
    padding: 10px;
    font-size: 10px;
  }

 }

 @media (max-width: 200px){

  .btn-curriculum, .btn-book{
    padding: 4px;
    font-size: 6px;
  }

  
  .card{
    height: 300px;
  }

  .card-container{
    max-height: 300px;
  }


 }

  
  @media screen and (max-height: 665px) {
    .card-description{
      font-size: 16px;
    }

    .card-container{
      height: 650px;
    }

    .btn-curriculum, .btn-book{
      font-size: 16px;
    }

    .card-buttons{
      margin-top: 20px;
      margin-bottom: 20px;
    }
  
    .card-description{
      height: 200px;
    }
   
  }
  



  @media screen and (max-width: 400px) and (max-height: 665px) {

    .btn-curriculum, .btn-book{
      font-size: 10px;
    
    }

  }

  @media screen and (max-width: 200px) and (max-height: 665px) {

    .btn-curriculum, .btn-book{
      font-size: 6px;
    
    }

  }

  @media (max-width: 100px){



.btn-curriculum, .btn-book{
  padding: 2px;
  font-size: 3px;
}

.card-content{
  padding: 0;
}

.card{
  height: 150px
}

.card-container{
  max-height: 150px;

  }

}

  @media (max-width: 100px){
    .btn-curriculum, .btn-book{
      padding: 1px;
      font-size: 2px;
    }
  }