/* homePageCourses.css*/

.courses__btn {
    background-color: transparent;
    padding: 10px 20px;
    border: 2px solid #261752;
    border-radius: 300px;
    text-transform: uppercase;
    cursor: pointer;
    transition: border-color 0.3s ease;
    margin-bottom: 6vh;
}

.courses__btn:hover {
    border-color: #ffffff;
  }
  
  .courses__btn-text {
    color: white;
    background-image: linear-gradient(90deg, #7F57F1, #7700fe, #F2B09F);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 2vw;
   
  }


.title{
    font-size: 3vw;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 6vh;
}

.code-games {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Align children (slider and arrows) in the center */
    align-items: center;
    width: 100%; /* Full width of the viewport */
    position: relative; /* For absolute positioning of arrows */
    padding: 20px 0; /* Vertical padding */
 
}


.header {
    margin-top: 20px;
    font-size: 24px;
    color: white;
}





@media(max-width: 767px){
    .courses__btn-text, .title{
font-size: 32px;
    }
}



/* Responsive adjustments for different screen sizes */
@media(max-width: 400px){

.title{
    font-size: 6vw;
}

.courses__btn-text{
    font-size: 6vw;
}




}

@media (max-width: 200px){
    .courses__btn{
        padding: 6px;
        padding-bottom: 12px;
    }
}

@media (max-width: 100px){
    .courses__btn{
        padding: 1px;
        padding-bottom: 6px;
    }
}