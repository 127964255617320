.terms-section {
    margin-bottom: 5rem;

  }
  
  .terms-header {
    text-align: center;
    margin-top: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .terms-header {
      margin-top: 8rem;
    }
  }
  
  .terms-title {
    font-size: 2.25rem;
    color: white;
    font-weight: bold;
    width: 46%;
    margin: 0 auto;
  }
  
  @media (min-width: 768px) {
    .terms-title {
      font-size: 3rem;
      width: 100%;
    }
  }
  
  .terms-content {
    display: flex;
    flex-direction: column;
    padding-right: 0.75rem;
    padding-left: 1.25rem;
    max-width: 100%;
    margin: 2.25rem auto 4rem;
  }
  
  @media (min-width: 768px) {
    .terms-content {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      max-width: 48rem;
    }
  }
  
  @media (min-width: 1024px) {
    .terms-content {
      max-width: 70rem;
    }
  }
  
  .terms-section-title {
    color: #6366f1;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
    padding-right: 20px;
  }
  
  @media (min-width: 768px) {
    .terms-section-title {
      font-size: 1.875rem;
      line-height: 2.25rem;
      margin-bottom: 0.75rem;
      margin-top: 2.25rem;
    }
  }
  
  .terms-section-text {
    color: #94a3b8;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 20px;
  }
  
  @media (min-width: 768px) {
    .terms-section-text {
      font-size: 1.125rem;
      line-height: 1.75rem;
      margin-bottom: 0.75rem;
    }
  }
  
  .terms-list-disc {
    list-style-type: disc;
    padding-left: 3rem;
  }
  
  .terms-contact-link a {
    color: #8b5cf6;
    text-decoration: none;
  }
  
  .terms-contact-link a:hover {
    color: #f59e0b;
  }
  
  .terms-footer-text {
    color: #94a3b8;
    font-size: 0.75rem;
    line-height: 1rem;
    padding-left: 1.25rem;
    margin-top: 2.5rem;
  }
  
  @media (min-width: 768px) {
    .terms-footer-text {
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-left: 1.25rem;
    }
  }

  @media (max-width: 400px){
    .terms-title{
      font-size: 6vw;
    }

    .terms-section-title{
      font-size: 5vw;
      line-height: 1.25em;
    }

    .terms-section-text, .terms-footer-text{
      font-size: 3.5vw;
      line-height: 1.25em;
      padding-left: 0px;
    
    }

    .terms-list-disc{
      padding-left: 1rem;
    }

    
  }
  