/*homePageTop.css*/
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;600&family=Rubik+Doodle+Shadow&display=swap');
@import url("https://fonts.googleapis.com/css?family=Sofia");





.slider-top-container {
  
  position: relative;
  background-image: linear-gradient(to bottom, rgba(70, 0, 120, 0.1), rgba(140, 0, 255, 0.1));
  border-bottom: 1px solid #9600dc; 
  border-top: 1px solid #9600dc; 
  box-shadow: 
  inset 0px 8px 10px -6px rgba(29, 0, 144, 0.9), /* Deep blue inside top shadow, more pronounced */
  inset 0px -8px 10px -6px rgba(70, 0, 72, 0.9), /* Deep purple inside bottom shadow, more pronounced */
  10px 10px 20px -5px rgba(29, 0, 144, 0.7), /* Soft blue outside right-top shadow, larger and softer */
  -10px -10px 20px -5px rgba(70, 0, 72, 0.7); /* Soft purple outside left-bottom shadow, larger and softer */


   
  margin: 50px 0px;
 
  overflow: hidden; /* Allows the popping-out effect to be visible outside the slider box */
  border-radius: 0px 100px 0px 100px;
  height: auto; /* Height is set to the space required by the contents */
  overflow-y: auto; /* Allows vertical scrolling if the content exceeds viewport height */
 
}

.slide-container {
  display: flex;
  flex-wrap: nowrap; /* Ensure slides are in a single line */
  padding-bottom: 5vh;

  
  width: 100%; /* Ensure it covers full area necessary */
  height: 100%; /* Full height */
}


.slide {
  flex: 0 0 100%; /* Each slide takes full width of the slider */
  display: flex;
  flex-direction: row; /* Align items horizontally */
  align-items: center; /* Center items vertically */

  
}





.text-container {
  width: 50%; /* Allocates half of the slide for text */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2vw; /* Use viewport width for responsive padding */
  box-sizing: border-box;
  padding-top: 10px; /* Fixed top padding */
  
 
}

.text-container h2 {
  
  margin-bottom: 6vh; /* Space between header and paragraph */
  width: 100%;
  text-align: center;
  font-size: 3vw; /* Responsive font size */
  
}

.text-container p {
  width: 100%;
  text-align: justify;
  font-size: 1.25vw; /* Responsive font size */
 
 
}

.slider-top-container .gif-container {
  width: 50%; /* Allocates the other half of the slide for the GIF */

margin-top: 3.5vh;
  display: flex;
  align-items: center; /* Centers the GIF vertically */
  justify-content: center; /* Centers the GIF horizontally */
  padding: 2vw; /* Responsive padding */


}

.gif-container img{

  border-radius: 2000px;
  max-height: 60vh;

}

.dots {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #888;
}



.top-h1-text {

  font-size: 6vw; /* Example font size, adjust as needed */
  font-weight: bold; /* Normal font weight for the whole line */
  text-align: center; /* Centers the text */
  padding: 10px 0; /* Adds padding above and below the text */

  
}




.first-line{
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  margin-bottom: -20px;

  animation: typing 4.75s linear forwards;
}


@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}





.flip-word {
  display: inline-block;
  perspective: 1000px;
  font-family: 'Rubik Doodle Shadow', system-ui;
  color: #A67CFF;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-shadow: 7px 7px 10px rgba(0, 0, 0, 0.5);
  transform: translateX(2px);
  animation: flip-in 2s infinite;
  animation-delay: 2s; /* Delay the start of the animation */
  visibility: hidden; /* Start with the word invisible */
}



@keyframes flip-in {
  0% {
    transform: rotateX(-90deg);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
    
  }
}



.firy-text {
  
  animation: flicker 1s infinite alternate;
  
}

@keyframes flicker {
  0% {
    text-shadow:
      0 0 4px #B294FF,
      0 0 10px #B294FF,
      0 0 20px #9B82E3,
      0 0 40px #9B82E3,
      0 0 80px #8169CC;
  }
  50% {
    text-shadow:
      0 0 4px #A280FF,
      0 0 10px #A280FF,
      0 0 20px #9171E6,
      0 0 40px #9171E6,
      0 0 80px #7A5ECD;
  }
  100% {
    text-shadow:
      0 0 4px #B294FF,
      0 0 10px #B294FF,
      0 0 20px #9B82E3,
      0 0 40px #9B82E3,
      0 0 80px #8169CC;
  }
}



.passion{
  color: #ffffff;
  font-family: "Sofia", sans-serif;
}



.plain-header{

  color: rgb(255, 255, 255);
}

.button-container{
  display: flex;
  justify-content: center;
}


.trial-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 20px 20px;

  padding: 12px 24px;
  font-size: 1.5vw;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #4b0082, #9932cc);
  border: 1px solid #708090;
  border-radius: 12px;
  text-decoration: none;
  transition: background-color 200ms ease-in-out;
  text-align: center;
  margin-bottom: 100px;
}

.trial-button:hover {
  background: linear-gradient(to right, #4b0082, #9932cc, #ba55d3);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.trial-button:focus {
  outline: none;

}


.typewriter-wrapper{
  margin-top: 100px;
}


@media( max-width: 1000px){
  .typewriter-wrapper{
    margin-top: 40px;
  }
}


/* Responsive adjustments for smaller screens */


/* Even smaller screens adjustments */
@media (max-width: 767px) {
  .slider-top-container{
    height: auto; /* Height is set to the space required by the contents */
    overflow-y: auto; /* Allows vertical scrolling if the content exceeds viewport height */
    border-radius: 0px 50px 0px 50px;
  }

  .typewriter-wrapper{
    font-size: 7vw;
  }
 
  .text-container {
    width: 80%; /* Stack text and GIF vertically on very small screens */
   height: 50%;
    padding-top: 15px;
    margin-bottom: 30px; /* Less space between header and paragraph */
  
  }

  .gif-container {
    width: 100%; /* Stack text and GIF vertically on very small screens */
  
    padding-top: 15px;
    margin-bottom: 40px; /* Less space between header and paragraph */
  
  }

  .gif-container img{
    max-height: 50vh;
max-width: 130%;
  }

  .what-makes-us-best .gif-container {
    order: 2; /* Change order for smaller screens */
  }
  .what-makes-us-best .text-content {
    order: 1; /* Change order for smaller screens */
  }
  .text-container h2 {
    margin-top: 30px;
    font-size: 6vw; /* Even larger font size for readability */
    margin-bottom: 30px; /* Less space between header and paragraph */
    
   
  }

  .text-container p {
    font-size: calc(8px + 1vw);
  }


  .top-h1-text{
    font-size: 8vw;
  
  }

.first-line{
  margin-bottom: -5px;
}

  

  .slide {
    flex-direction: column; /* Stack text and GIF containers vertically */
  }

  .dots {
  
    bottom: 10px;
    margin-left: 6px;

  }

  .dot{
    height: 5px;
    width: 5px;;
  }

  
.slide-container {

  padding-bottom: 1vh;
}

.trial-button{
  font-size: 4vw;
}

}

@media (max-width: 400px){

  .text-container p{
    font-size: 8px;
  }
}


@media (max-width: 300px) {

  
  .top-h1-text{
    font-size: 8.5vw;
  
  }

  .trial-button {
    padding: 12px 10px;
  }

  .first-line{
    margin-bottom: 0px;
  }

  .text-container{
    height: auto;
  }

  .gif-container img{
    max-height: 50vh;
max-width: 180%;
  }
  
  .text-container p{
    font-size: 6px;
  }

}



@media (max-width: 150px) {
  .trial-button {
    padding: 6px 5px;
    font-size: 6px;
    margin: 10px 5px;

  }

  .dot{
    height: 2px;
    width: 2px;
   
  }

  .dots{
    margin-left: 8px;
  }

  .text-container p{
    font-size: calc(2.5px + 1vw);
  }
}