/* topNav.css */

.navbar {
  position: fixed; /* Make the navbar fixed to the top */
  top: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  background-image: linear-gradient(to top, #030213 0%, #260F4B 1%, #260F4B 95%, #030213 100%);
  color: white;
}
  
  .nav-links {
    display: flex;
    gap: 60px;
    margin-right:1.5vw;
    font-size: 2vw;
  }
  
  .nav-item {
    text-decoration: none;
    color: white;
  }
  
  .nav-logo {
    margin-right: 75px;
    font-size: 28px;
    font-size: 2.25vw;
  }
  
  .nav-logo .logo {
    text-decoration: none;
  }
  
  .text-code {
    color: #7A58FF;
  }
  
  .text-amp {
    background: linear-gradient(to right, #7A58FF 0%, #6C49B8 50%, #A67CFF 100%);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .text-game {
    color: #A67CFF;
  }
  
  .nav-buttons {
    display: flex;
    gap: 20px;
    font-size: 2vw;
  }
  
  .intro-button {
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: transform 0.2s;
    background: linear-gradient(to right, #3805af, #530268);
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .start-button {
    text-decoration: none;
    padding: 10px 0px 10px 10px;
    border-radius: 5px;
    transition: transform 0.2s;
    
    color: white;
    border: none;
    cursor: pointer;
  }

  .intro-button:hover, .start-button:hover {
    transform: scale(1.1);
  }
  
  /* Hamburger menu styles */
  .hamburger {
    display: none; /* Hidden on desktop and larger tablets */
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
    height: 34px;
    
  }
  
  .hamburger span {
    display: block;
    width: 30px;
    height: 5px;
    background-color: white;
    margin: 5px 0;
    transition: all 0.3s ease;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .nav-links {
      gap: 20px;
      margin-right: 2.75vw;
      font-size: 18px;
    }
    
    .nav-logo {
      margin-right: 55px;
      font-size: 24px;
    }
    .nav-buttons{
      font-size: 20px;
    }
  }
  
  @media (max-width: 1000px) {
    .nav-links {
      font-size: 14px;
      margin-left: 0.75vw;
    }
    
    .intro-button, .start-button {
      padding: 10px 10px;
      font-size: 12px;
    }
    
    .nav-logo {
      margin-right: 20px;
      font-size: 22px;
    }
    
    .navbar {
      padding: 20px 30px;
    }
  }
  
  @media (max-width: 767px) {
    .navbar {
        flex-direction: row; /* Keep items aligned horizontally */
        justify-content: space-between; /* Place items at opposite ends */
        align-items: center; /* Center items vertically */
        padding: 10px 15px; /* Adjust padding for overall navbar */
        border-bottom: 2px solid white; /* White border for division */
        position: relative; /* Position relative for positioning nav-links absolutely under this */
        position: fixed; 
        width: 100%; /* Ensure it spans the full width */
        top: 0; /* Ensure it stays at the top */
     
      }

    .hamburger {
      display: block;
      z-index: 2;
    }
    
    .nav-links, .start-button {
      display: none; /* Hide by default on mobile, will show when hamburger is clicked */
    }
    
    .nav-links.show {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: calc(100% - 30px); /* Adjust width to include margins */
      padding: 10px 15px; /* Added padding on top and same for left-right for consistency */
      position: absolute; /* Positioned absolutely to float below the navbar */
      top: 100%; /* Position it directly below the navbar */
      left: 15px; /* Left margin */
      right: 15px; /* Right margin for consistency */
      background-color: #260F4B; /* Background for visibility */
      box-shadow: 0 2px 5px rgba(0,0,0,0.5); /* Optional: adds shadow for better layering */
      z-index: 2; /* Below the navbar z-index to keep click areas clear */
    }
    
    .nav-logo {
        display: none; /* Hide the logo on mobile to save space */
    }
    
    .nav-buttons {
        justify-content: flex-end; /* Align the intro button to the right */
        flex: 1; /* Takes the remaining space to push the button to the edge */
    }
    
    .intro-button {
        text-align: center; /* Center text inside the button for aesthetics */
    }
}


@media (max-width: 300px) {
  .intro-button {
    font-size: 9px;
    padding: 5px 5px;
}

.hamburger{
  height: 22px;
}

.hamburger span {
 
  width: 20px;
  height: 3px;
  margin: 3px 0;
  
}
}


@media (max-width: 150px) {
  .intro-button {
    font-size: 8px;
    padding: 3px 3px;
    
}

.navbar{
  padding: 5px;
}

.hamburger{
  height: 15px;
}

.hamburger span {
 
  width: 12px;
  height: 1px;
  margin: 3px 0;
  
}

.nav-item{
  font-size: 8px;
}
}

@media (max-width: 90px){
  .nav-item{
    font-size: 6px;
    
  }

  .nav-links.show{
    padding: 5px;
    width: 80vw;
 left: 0px;
 right: 0px;
 margin-left: 10vw;
    
  }
}

@media (max-width: 75px){
  .nav-item{
    font-size: 4px;
  }
}

