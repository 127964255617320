/* curriculumModal.css*/

.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal-content {
    position: relative;
    padding: 16px;
    width: auto;
    max-width: 440px; /* 22rem in pixels */
    max-height: 90vh;
    margin-top: 20px; /* Adjustment for smaller screens */
    background: linear-gradient(to top right, #20202b, #31015d, #4b0082); /* Adjusting gradient for better cross-browser compatibility */
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Corrected property */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    color: #e0e0e0; /* Slate color for text */
}


.modal-header, .modal-footer {
    padding: 10px 20px;
}

.modal-header h3 {
    font-size: 24px; /* 2xl in pixels */
    font-weight: bold;
    text-align: center;
}

.modal-body {
    padding: 8px 20px;
    overflow-y: auto;
}

.modal-body ol {
    counter-reset: section;
    padding-left: 20px; /* Adjusted padding for consistency */
}

.section {
    counter-increment: section;
    margin-bottom: 20px;
}

.section h5 {
    font-weight: medium;
    font-size: 18px; /* lg in pixels */
}

.section ol {
    list-style-type: disc;
    margin-top: 4px;
    padding-left: 20px;
}

.modal-footer button {
    padding: 10px;
    width: 100%;
    text-align: center;
    background: linear-gradient(to left, #20202b, #31015d, #4b0082);
    color: white;
    font-size: 16px; /* Text size large */
    border-radius: 10px;
    border: 1px solid #707070;
    cursor: pointer;
    transition: background 0.3s;
}

.modal-footer button:hover {
    background: linear-gradient(to right, #20202b, #31015d, #4b0082);
}
