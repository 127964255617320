.privacy-policy-section {
    margin-bottom: 5rem;
 
  }
  
  .privacy-policy-header {
    text-align: center;
    margin-top: 1.5rem;
  }
  
  .privacy-title {
    font-size: 2.25rem;
    color: white;
    font-weight: bold;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding-right: 0.75rem;
    padding-left: 1.25rem;
    max-width: 100%;
    margin: 2.25rem auto 4rem;
  }

  
  .pp-section-title {
    color: #6366f1;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
    padding-right: 20px;
  }
  
  
  
  .pp-section-text {
    color: #94a3b8;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 20px;
     text-align: justify;
  }
  
 
  
  .pp-list-disc {
    list-style-type: disc;
    padding-left: 3rem;
  }
  
  .contact-link a {
    color: #8b5cf6;
    text-decoration: none;
  }
  
  .contact-link a:hover {
    color: #f59e0b;
  }
  
  

  @media(min-width: 1200px){
  
  }

  @media (min-width: 1024px) {
    .content {
      max-width: 70rem;
    }
  }
  

  @media (min-width: 768px) {
    .privacy-policy-header {
      margin-top: 8rem;
    }
  
    .privacy-title {
      font-size: 3rem;
    }
 
    .content {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      max-width: 48rem;
 
    }

    .pp-section-title {
      font-size: 1.875rem;
      line-height: 2.25rem;
      margin-bottom: 0.75rem;
      margin-top: 2.25rem;
    }

    .pp-section-text {
      font-size: 1.125rem;
      line-height: 1.75rem;
      margin-bottom: 0.75rem;
    }
  }


  @media (max-width: 400px){
    .privacy-title{
      font-size: 6vw;
    }

    .pp-section-title{
      font-size: 5vw;
      line-height: 1.25em;
      padding-right: 0px;
    }

    .pp-section-text{
      font-size: 3.5vw;
      line-height: 1.25em;
      padding-left: 0px;
     
    }

    .pp-list-disc{

      padding-left: 1rem;
    }
  }