/* homePageMiddle.css */
@import url("https://fonts.googleapis.com/css?family=Sofia");


.what-to-expect h1 {
  text-align: center;
  font-size: 3vw;
  margin-top: 4vh;
  margin-bottom: 8vh;
}

.guarantees h2,
.process h2 {
  text-align: center;
  font-size: 3vw;
}

.process {
  font-family: 'Sofia', sans-serif;
  text-align: center;
  margin-bottom: 2vw;
}

.process-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4vh;
  margin-top: 4vh;

}

.step {
  display: flex;
  flex-direction: column;
  /* Stack elements vertically */
  align-items: center;
  /* Center-align items */
  justify-content: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15),
    /* Smaller blur, more pronounced */
    0 12px 24px rgba(0, 0, 0, 0.15),
    /* Medium blur */
    0 16px 32px rgba(0, 0, 0, 0.15);
  /* Larger blur for increased 3D effect */
  border-radius: 8px;
  /* Optional: rounded corners for the box */

  margin: 1vw;
  /* Space between each step */
  width: 20vw;
  min-height: 15vw;
}

.step img {
  width: 20%;

  order: -1;
  /* Position image above text and arrow */
}

.step-text {
  font-size: 1.5vw;
  order: 1;
  /* Ensure the text is below the image */
  padding: 1vw;
  /* Padding around the text */
}

.arrow {
  font-size: 1.5vw;

  padding: 1vw;
  /* Padding around the arrow */
}

.guarantees {

  font-family: 'Sofia', sans-serif;
}



.guarantee-items {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.guarantee {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3 - 20px);
  /* Divide the row into thirds, accounting for margin */
  height: 15vw;
  /* Set a fixed height, adjust as necessary */
  margin: 30px 10px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15),
    0 12px 24px rgba(0, 0, 0, 0.15),
    0 16px 32px rgba(0, 0, 0, 0.15);
  /* 3D shadow effect */
  border-radius: 8px;
  /* Rounded corners */
  overflow: hidden;
  /* For border-radius */

}

.main-text {
  font-size: 1.5vw;
}

.sub-text {
  font-size: 1.3vw;
  /* Half the size of main-text, assuming main-text is 1.2vw */
  font-family: sans-serif;
}

.mission__btn {
  margin-top: 60px;
  background-color: transparent;
  padding: 10px 20px;
  border: 2px solid #261752;
  border-radius: 100px;
  text-transform: uppercase;
  cursor: pointer;
  transition: border-color 0.3s ease;
  display: block; 
  margin-left: auto; 
  margin-right: auto; 
}


.mission__btn:hover {
  border-color: #ffffff;
}

.mission__btn-text {
  color: white;
  background-image: linear-gradient(90deg, #7F57F1, #7700fe, #F2B09F);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 3vh;
 
}

/* Section container styles */
.fit-for-you,
.what-makes-us-best {
  display: flex;
  flex-direction: column;
  /* Stack h2 and content containers vertically */
  align-items: center;
  margin: 10vh 2vw;
  /* Healthy margin between sections */
  box-shadow:
    0 15px 25px rgba(0, 0, 0, 0.35),
    /* Darker shadow for medium depth */
    0 25px 50px rgba(0, 0, 0, 0.25),
    /* Larger, softer shadow for extended depth */
    0 5px 5px rgba(0, 0, 0, 0.4),
    /* Small, sharp shadow for close-up effect */
    0 50px 80px rgba(0, 0, 0, 0.15),
    /* Very large, very soft shadow for distant depth */
    0 -5px 5px rgba(0, 0, 0, 0.3) inset;
  /* Inset shadow for a slight inner depth */

  border-radius: 20px;
  /* Rounded corners for the sections */
}

/* Header styles */
.fit-for-you h2,
.what-makes-us-best h2 {
  text-align: center;
  width: 100%;
  /* Header takes full width */
  padding: 2vh 0;
  /* Spacing above and below the header */
  font-family: 'Sofia', sans-serif;
  font-size: 3vw;
}

/* Flex container for side-by-side content */
.fit-for-you .content-container,
.what-makes-us-best .content-container {
  display: flex;
  width: 100%;
  /* Full width for the flex container */
  padding: 20px;
  align-items: center;
}

/* Flex container for side-by-side content modified for vertical layout in text-content */
.fit-for-you .text-content,
.what-makes-us-best .text-content {
  display: flex;
  /* Enable flex layout */
  flex-direction: column;
  /* Stack children (p tags) vertically */
  width: 50%;
  /* Each takes up half the width */
  height: 100%;
  /* Take up full height of the parent container */
  padding: 20px;


}

/* Styles for paragraphs to appear as bullet points and fill the container */
.fit-for-you .text-content p,
.what-makes-us-best .text-content p {
  text-align: justify;
  width: 100%;
  /* Ensure the paragraph does not exceed the container width */
  margin: 8px 0;
  /* Margin between paragraphs, can adjust as needed */
  font-size: 1.5vw;
  padding: 0;
  /* No additional padding inside paragraphs */



  display: flex;
  /* Use flex layout for paragraphs */
  align-items: center;
  /* Vertically center text within each paragraph */

}



.fit-for-you .gif-container,
.what-makes-us-best .gif-container {
  width: 50%;
  /* Each takes up half the width */
  padding: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

/* Make the images round */
.fit-for-you .gif-container img,
.what-makes-us-best .gif-container img {
  width: auto;
  /* Keep image aspect ratio */
  height: auto;
  /* Keep image aspect ratio */
  max-width: 100%;
  /* Image max size is container width */
  max-height: 60vh;
  /* Image max height is container height */
  border-radius: 50%;
  /* Make image round */
}



/* Keyframes for sequential appearance with complete stay and reset */

@keyframes fadeInText1 {

  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0
  }
}

@keyframes fadeInArrow1 {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0
  }
}

@keyframes fadeInText2 {


  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  35% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0
  }
}

@keyframes fadeInArrow2 {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0
  }
}

@keyframes fadeInText3 {


  0% {
    opacity: 0;
  }

  55% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0
  }
}

@keyframes fadeInArrow3 {
  0% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0
  }
}

@keyframes fadeInText4 {

  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  85% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0
  }
}


.step:nth-child(1) {
  animation: fadeInText1 10s infinite;
  /* Animation for the first element */
}

.arrow:nth-child(2) {
  animation: fadeInArrow1 10s infinite;
  /* Animation for the second element */
}

.step:nth-child(3) {
  animation: fadeInText2 10s infinite;
}

.arrow:nth-child(4) {
  animation: fadeInArrow2 10s infinite;
  /* Animation for the second element */
}

.step:nth-child(5) {
  animation: fadeInText3 10s infinite;
}

.arrow:nth-child(6) {
  animation: fadeInArrow3 10s infinite;
  /* Animation for the second element */
}

.step:nth-child(7) {
  animation: fadeInText4 10s infinite;
}





@media (max-width: 767px) {

  .fit-for-you .text-content,
  .what-makes-us-best .text-content {

    padding-left: 1px;
    padding-right: 1px;
    width: 100%;
 

  }

  .fit-for-you .text-content p ,
  .what-makes-us-best .text-content p {

    font-size: 2.5vw;

  }


  .fit-for-you h2, .what-makes-us-best h2{
    font-size: 5vw;
  }

  .fit-for-you .content-container,
  .what-makes-us-best .content-container {
    width: auto;
    flex-direction: column;
  }

  .fit-for-you .text-content,
  .what-makes-us-best .text-content,
  .fit-for-you .gif-container,
  .what-makes-us-best .gif-container {
    padding: 0;

  }

  .fit-for-you .gif-container,
  .what-makes-us-best .gif-container {

    width: 100%;
    margin-top: 40px;
    margin-left: 0px;
  }

  .fit-for-you .gif-container img, .what-makes-us-best .gif-container img{
    max-height: 45vh;
  }

  /* Adjustments for the process steps */
  .process-steps {
    flex-direction: column;
    /* Stack elements vertically */
    align-items: center;
    /* Center-align items */

  }

  .step {

    width: 90%;
    /* Make each step take full width of the container */
    min-height: 1vh;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 0.1px solid rgb(0, 0, 0);


  }

  @media (aspect-ratio: 1/1) {
    .step {
      max-height: 8vw;
      font-size: 10vw;
    }
  }

  @media (min-aspect-ratio: 2/1) {
    .step {
      max-height: 8vw;
      font-size: 10vh;
    }
  }

  /* Update arrows to point downwards */
  .arrow {
    display: block;
    /* Ensure arrows are block level for vertical stacking */
    transform: rotate(90deg);
    /* Rotate arrow to point down */
    margin: 2vh 0;
    /* Add margin for spacing between steps */
    font-size: 8vw;
  }

  /* Adjustments for the guarantee items */
  .guarantee-items {
    flex-direction: column;
    /* Stack guarantees vertically */
    align-items: center;
    /* Center-align items */
    margin-top: 20px;



  }

  .guarantee {
    width: 90%;
    /* Each guarantee takes full width */
    padding: 20px;
    height: auto;

  }

  .step-text {
    font-size: 5vw;
    margin-top: 20px;


  }

  .guarantees h2,
  .process h2 {

    font-size: 10vw;
  }


  .main-text {
    font-size: 5vw;
  }

  .sub-text {
    font-size: 3.5vw;

  }



  .step:nth-child(1) {
    animation: fadeInText1 5s infinite;
    /* Animation for the first element */
  }

  .arrow:nth-child(2) {
    animation: fadeInArrow1 5s infinite;
    /* Animation for the second element */
  }

  .step:nth-child(3) {
    animation: fadeInText2 5s infinite;
  }

  .arrow:nth-child(4) {
    animation: fadeInArrow2 5s infinite;
    /* Animation for the second element */
  }

  .step:nth-child(5) {
    animation: fadeInText3 5s infinite;
  }

  .arrow:nth-child(6) {
    animation: fadeInArrow3 5s infinite;
    /* Animation for the second element */
  }

  .step:nth-child(7) {
    animation: fadeInText4 5s infinite;
  }




}

@media(max-width: 400px){

 
  
  .mission__btn-text{
      font-size: 6vw;
  }
  
  
  
  
  }
  
  @media (max-width: 200px){
      .mission__btn{
          padding: 3px;
          padding-bottom: 8px;
      }

      .fit-for-you .gif-container,
  .what-makes-us-best .gif-container {
    width: 95%;
    margin-bottom: 20px;
  }


  .guarantee{
    margin: 0px;
    margin-top: 20px;
  }

  }
  

@media (max-width: 100px) {

  .fit-for-you,
  .what-makes-us-best {
    margin: 5vh 1vw;
    /* Reduced margins */
  }

  .fit-for-you .content-container,
  .what-makes-us-best .content-container,
  .fit-for-you .text-content,
  .what-makes-us-best .text-content,
  .fit-for-you .gif-container,
  .what-makes-us-best .gif-container {
    padding: 2px;
    /* Minimal padding to ensure content uses maximum available width */
  }


  .fit-for-you .gif-container,
  .what-makes-us-best .gif-container {
    width: 90%;

  }



}