/* topPromo.css*/



.promoBanner {
    width: 100vw;
    text-align: center;
    position: relative;
    top: 0;
    z-index: 4;
    background: linear-gradient(to bottom right, #e9d5ff, #a855f7); /* bg-gradient-to-br from-purple-200 to-purple-500 in Tailwind */
   padding: 10px;
   font-size: 1.8vw;
   overflow:hidden;
}

.contactInfo {
    color: #7f1d1d; /* text-red-900 in Tailwind */
}

.phone,
.email {
    display: inline-block;
    font-weight: bold; /* font-bold in Tailwind */
    margin-right: 0.5rem; /* mr-2 in Tailwind */
    cursor: pointer;
    transition: color 0.3s;
}

.phone:hover,
.email:hover {
    color: #facc15; /* hover:text-yellow-500 in Tailwind */
}

.separator {
    display: inline-block;
    font-weight: bold; /* font-bold in Tailwind */
    margin-right: 0.5rem; /* mr-2 in Tailwind */
}

.promoText {
    color: #7f1d1d; /* text-red-900 in Tailwind */
    font-weight: bold; /* font-bold in Tailwind */
   margin-top: 5px;
}

@media (max-width: 767px) {

    .promoText, .contactInfo{
        font-size: 12px;
    }
   
}


@media (max-width: 400px) {

    .promoText, .contactInfo{
        font-size: 10px;
    }
   
}


@media (max-width: 250px) {

    .promoText, .contactInfo{
        font-size: 8px;
    }
   
}

@media (max-width: 150px) {

    .promoText, .contactInfo{
        font-size: 6px;
      

    }
    .promoBanner{
        padding: 4px;
    }


   
}

@media(max-width: 90px){


       .promoText, .contactInfo{
        font-size: 3.3px;
      

    }
    .promoBanner{
        padding: 2px;
    }
}
