/* footer.css */

.footer {
  position: relative;
  }
  

  
  .footer-links-small {
    width: 100%;
    margin: 0 auto;
    padding: 0.25rem;
    margin-bottom: 0.5rem;
    font-size: 11px; /* Adjust size per breakpoints */
    display: flex; /* Ensures flexbox properties are applied */
    align-items: center; /* Centers the links vertically */
    justify-content: center; /* Centers the links horizontally */
  }
  
  .flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; /* Ensures this container takes full width */
  }
  

  .link-list-small {
    width: 100%;
    display: flex;
    justify-content: space-evenly; /* This will evenly space the links across the available space */
    text-align: center;
    color: #808080;
    padding: 0; /* Ensure no default padding affects alignment */
    list-style: none; /* Remove bullets */
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .link-list-large {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #808080;
    padding: 0; /* Ensure no default padding affects alignment */
    list-style: none; /* Remove bullets */
  }
  
  .link-item {
    margin: 0 1rem; /* Uniform margin for all links */
  }
  
  .link {
    color: #808080;
    text-decoration: none;
    font-size: 1vw;

  }
  
  .link:hover {
    text-decoration: underline;
  }
  
  .text-center {
    width: 100%;
    text-align: center;
  }
  
  .footer-text {
    color: #808080;
    
  }
  
  .footer-links-large {
    display: none;
    width: 100%;
    max-width: 1200px; /* Adjust based on breakpoints */
    padding: 1rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
  }
  
  .footer-links-inner {
    margin-bottom: 3.5rem;
    width: 100%;
  }
  
  .link-list-large {
    flex-grow: 1; /* Allows the list to fill its container */
    justify-content: space-around; /* Adjust this if links need more balanced spacing */
  }
  

  @media (max-width: 1024px){
    .footer-links-small {
        display: flex;
      }
    
      .footer-links-large {
        display: none;
      }

      .link{
        font-size: 11px;
      }
  }
  
  @media (min-width: 1024px) {
    .footer-links-small {
      display: none;
    }
  
    .footer-links-large {
      display: flex;
    }
  
    .link-list-large {
        display: flex;
        align-items: center;
        font-size: 16px; /* Adjust size per breakpoints */
        color: #808080;
        justify-content: space-around; /* Distributes space evenly between items */
      }
  }
  

  @media (max-width: 300px){
    .link-item{
     margin-top: 5px;
     font-size: 8px;
    }

    .footer-text{
      font-size: 8px;
    }

    .link{
      font-size: 8px;
    }
  }

  @media (max-width: 100px){
    .link-item{
      margin-right: 0;
      margin-left: 0;
    }

    .footer-text, .link{
      font-size: 6px;
    }
  }