
  
  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

  }
  
  .button-container {
    margin-top: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .main-button {

    background-color: transparent;
    padding: 0.25rem 1.5rem;
    border-radius: 300px;
    border: 2px solid #261752;
    cursor: default;
    text-transform: uppercase;
    margin-top: 3rem;
    margin-bottom: 20px;
    width: fit-content;
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
   
  
  }

  .main-button:hover{
    border-color:#ffffff;
  }
  
  .button-text {
    font-size: 3vh;
    background-image: linear-gradient(to right, #7F57F1, #7700fe, #F2B09F);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
   
  }
  
  .main-title {
    font-size: 4vh;
    color: white;
    text-align: center;
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
  }
  
  .sub-title {
    color: #6366f1;
    font-size: calc(1.3vw + 0.5vh + 0.5vmin);
    text-align: center;
    margin-bottom: 1.75rem;
  }
  
  .description {
    color: #718096;
    text-align: center;
    font-size: calc(1.3vw + 0.5vh + 0.5vmin);
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .roblox-minecraft-card-container {
    width: 100%;

    margin: 0 auto;
    font-size: calc(1.3vw + 0.5vh + 0.5vmin);
   
  }
  
  .roblox-minecraft-card {
    display: flex;

    background-image: linear-gradient(to top right, #030213 10%, #261752 90%);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    overflow: visible;
    position: relative;
    margin: 4vw;
    min-height: 30vw;

  }
  
 
  
  .roblox-minecraft-card-image {
    width: 30%; /* Double the width */
    object-fit: cover;
    position: absolute;
  
   
    height: 80%;

  }

  .roblox-card{
    margin-right: 15%;
  }

  .roblox-image{
    position: absolute;
    right: -15%;
   top: 10%;
  }
  

  
  .minecraft-card{
    margin-left: 15%;
  }

  .minecraft-image{
    position: absolute;
    left: -15%;
   top: 10%;
  }
  
 
  
  .roblox-minecraft-card-content {
    width: 70%;
    padding: 20px;
    z-index: 1; /* Ensure the text is over the image */
    display: flex; /* Use flexbox to layout the content */
  flex-direction: column; /* Stack children elements vertically */
  justify-content: space-between;
 

  }
  
  .roblox-card .roblox-minecraft-card-content {
    text-align: left;
    align-items: flex-start; /* Align the text to the left */
    margin-left: 2%
  }

 
  .minecraft-card .roblox-minecraft-card-content {
    text-align: right;
    align-items: flex-end; /* Align the text to the right */
    margin-left: 30%;
  }
  
  .roblox-minecraft-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    
  }
  
  .roblox-title, .minecraft-title {
    color: white;
    font-size: 4vh;
    font-weight: bold;
  }
  
  .custom-pricing {
    color: #718096;
    font-size: 1vh;
    font-weight: bold;
  margin-left: 20px;
  margin-top: 9px;
  }
  
  .roblox-minecraft-card-buttons {
    display: flex;
    justify-content: flex-start; /* Align buttons to the left */
    flex-wrap: nowrap; /* This prevents the buttons from wrapping into a new line */
  }
  
  .secondary-button {
    background-color: transparent;
    color: #D559FF;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    border: solid 1px #261752;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    flex: 1 1 auto; /* Allows the button to shrink and grow but fill the space */
    
  }
  
  .enquire-link {
    background-image: linear-gradient(to right, #4f46e5, #9333ea);
    color: white;
    padding: 0.25rem 1rem;
    border-radius: 9999px;
    border: solid 1px #718096;
    text-decoration: none;
    font-size: calc(1.3vw + 0.5vh + 0.5vmin);
    font-weight: medium;
    margin-top: 1rem; /* Add space between the link and the preceding text */
  display: block;
  }
  
  .roblox-card .enquire-link {
    align-self: flex-start; /* Align button opposite the image */
  }
  
  .minecraft-card .enquire-link {
    align-self: flex-end; /* Align button opposite the image */
  }



  .roblox-text, .minecraft-text {
    color: #A67CFF; /* Matches text-indigo-500 */
    font-size: calc(1.3vw + 0.5vh + 0.5vmin); /* Matches text-lg */
   
  }
  

  
 
  .course-description {
    margin-top: 12px; /* Matches mt-3 */
    margin-left: 8px; /* Matches ml-2 */
    margin-right: 8px;
    width: 95%; /* Matches w-full */
    text-align: justify; /* Matches justify-text */
    
  }
  

  .list {
    margin-right: 16px; /* Matches mr-4 */
    margin-left: 15px; /* Matches ml-10 */
    margin-top: 4px; /* Matches mt-1 */
    color: #F2B09F; /* Matches text-[#F2B09F] */
    list-style-type: none;
    font-size: calc(1.3vw + 0.5vh + 0.5vmin); /* Generic size, enhanced for md */
  }
  
  
  .list.minecraft li {
    position: relative; /* Positioning context for pseudo-element */
    padding-right: 20px;
    
    
  }
  
  .list.minecraft li::before {
  
    content: '•'; /* Bullet character */
    position: absolute;
    left: 100%; /* Position bullet to the right of the text */
    
  }



  .list.roblox li {
    position: relative; /* Positioning context for pseudo-element */
    padding-left: 20px;
    
    
  }
  
  .list.roblox li::before {
 
    content: '•'; /* Bullet character */
    position: absolute;
    right: 100%; /* Position bullet to the right of the text */
    
  }
 

  
  .final-note {
    color: white; /* Matches text-white */
    font-weight: bold; /* Matches font-black */
    margin-top: 12px; /* Matches mt-3 */
    margin-right: 16px; /* Matches mr-4 */
    font-size: calc(1.3vw + 0.5vh + 0.5vmin); /* Matches md:text-2xl */
  }
  
  @media (max-width: 1800px) {
    /* Adjust the container for the cards to allow the image to be on top */
    .roblox-minecraft-card {
        flex-direction: column; /* Stack elements vertically */
       
        
        padding: 20px 0; /* Provide padding around the content */
    }

    /* Positioning and sizing the images above the header */
    .roblox-minecraft-card-image {
        width: 75%; /* Adjust width to 3/4 of the container */
        min-height: 30vh; /* Maintain aspect ratio */
        
         /* Space between the image and the text content */
    }

    /* Full width content inside the container with some margin */
    .roblox-minecraft-card-content {
      
        width: 100%; /* Full width of the card */
        padding: 0 30px; /* Padding on the sides for some spacing from the container edge */
 
    }

    /* Ensure buttons and links are also centered under the new layout */
    .roblox-minecraft-card-buttons {
      display: flex;
      justify-content: center; /* Center the buttons within their container */
     
     
      width: 95%; /* Use the full width of the card for button placement */
      gap: 20px;
  }
  
  .secondary-button {
      background-color: transparent;
      color: #D559FF;
      padding: 0.25rem 0.5rem; /* Slightly reduce padding for small screens */
      border-radius: 9999px;
      border: solid 1px #261752;
      cursor: pointer;
      margin: 5px; /* Ensure spacing around each button when they wrap */
      text-transform: uppercase;
      min-width: 30%; /* Ensure buttons have a minimum width for legibility */
      text-align: center; /* Center the text inside buttons */
     
  }
  
  .roblox.enquire-link{
    margin-left: 39.5%;
  }


    .roblox-card{
      margin-right: 4%;
      margin-top: 30vh;
    }
  
 
  
    
    .minecraft-card{
      margin-left: 5%;
      margin-top: 30vh;
    }

 
  .roblox-image{
    position: relative;
   left: 12.5%;
   bottom: 15vh;


   
  
  }
  
.roblox.roblox-minecraft-card-header {
width: 35%;
justify-content: center;
margin-left: 32.5%;
margin-top: -8vh;
margin-bottom: 4vh;
    
  }
  

  .minecraft.enquire-link{
    margin-right: 39.5%;
  }

  .minecraft.roblox-minecraft-card-header {
    width: 35%;
    justify-content: center;
    margin-right: 32.5%;
    margin-top: -8vh;
    margin-bottom: 4vh;
        
      }

  .minecraft-image{
    position: relative;
    left: 12.5%;
    bottom: 15vh;
  }
  
  .minecraft-card .roblox-minecraft-card-content{
    align-items: end;
    margin-left: 0;
  }


}



@media (max-width: 767px){

  .list{
    margin-top: 10px;
  }

  .enquire-link.roblox{
  
    margin-left: 0;
  }

  .enquire-link.minecraft{
  
    margin-right: 0;
  }

 

  .button-text{
    font-size: 5vw;
  }
  
  .roblox-minecraft-card-buttons{
    gap: 0px;
    flex-direction: column;
  }




}


@media (max-width: 500px){
  .main-title{
    font-size: 8vw;
  }
.sub-title{
  margin-left: 5px;
  margin-right: 5px;

}

}

@media (max-width: 400px) {
 .description, .roblox-title, .minecraft-title, .roblox-text, .minecraft-text, .course-description, .final-note {
     font-size: calc(1.5vw + 0.5vh + 0.5vmin); /* Ensures a minimum of 4px font size plus scaling based on viewport width */
   padding: 0px 5px;
  }

  .sub-title{
    font-size: 4vw;
    padding: 0px 5px;
  }

  .main-title{
    font-size: 5vw;
  }

  .roblox-title, .minecraft-title{
    font-size: 6vw;
  }

  .main-button, .secondary-button, .enquire-link {
      padding: 0.1rem 0.4rem; /* Reduce padding to save space */
      font-size: calc(3px + 2vw); /* Slightly smaller than text to maintain button aesthetics */
  }

  .list li {
      font-size: calc(3px + 2vw); /* Ensures list items are also scaled down */
  }

  


  .roblox-minecraft-card-content, .roblox-minecraft-card-header {
      padding: 0.1rem; /* Reduce padding to create more space for text */
  }
  .course-description{
    margin-left: 1px;
    margin-right: 1px;
  }
 

  .list.minecraft li{
    padding-right: 4px;
  }

  .list.roblox li{
    padding-left: 4px;
  }
  
  .custom-pricing{
    margin-left: 5px;
    margin-top: 0px;
  }

  .enquire-link.roblox{
   

   margin-left: 3px;
  }

  .enquire-link.minecraft{
   

    margin-right: 3px;
   }

  .final-note{
    margin-right: 5px;
text-align: justify;
  }

  .roblox-text{
    text-align: justify;
    margin-right: 5px;
  }

  .minecraft-text{
    text-align: justify;
  }

  .roblox-text > .course-description{
    margin-right: 5px;
  }
}

@media (max-width: 100px){

  .list.minecraft li{
    padding-right: 3px;
    margin-top: 3px;
  }

  .list.roblox li{
    padding-left: 3px;
   
  }
  .list{
    margin-left: 8px;
    margin-right: 6px;
  }

  .button-text{
font-size: 7vw;
  }

  .roblox-minecraft-card-header{
    flex-direction: column;
  }
  
  .roblox-title, .minecraft-title{
    font-size: 16vw;
  }

  .custom-pricing{
    margin-left: 0px;
    font-size: 8vw;
  }



  .roblox-text, .minecraft-text{
    margin-top: 10px;
  }
  .description{
    margin-left: 5px;
    margin-right: 5px;
  }

  .roblox-card{
    margin-right: 0;
    margin-left: 0;
  }

  .minecraft-card{
    margin-left: 0;
    margin-right: 0;
  }

  .roblox-card .roblox-minecraft-card-content{
    margin-left: 0;
  }

   

}
